import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client'

Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO("https://40.113.44.125:7721", {
    withCredentials: false,
  }),
}));

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
