<template>
  <v-app id="inspire">
    <!-- <v-system-bar app color="blue darken-4">
      <v-spacer></v-spacer>
      <v-icon>mdi-square</v-icon>
      <v-icon>mdi-circle</v-icon>
      <v-icon>mdi-triangle</v-icon>
    </v-system-bar> -->

    <v-navigation-drawer app width="250" color="blue darken-3" dark>
      <div class="text-subtitle-1 text-center mt-2 white--text">Connected trackers ({{ devices.length }})</div>
      <v-list dense>
        <v-list-item
          v-for="device in devices"
          :key="device.device_id"
          link
          class="pr-0 py-2"
          @click="centerDeviceLocation(device.device_id)"
        >
          <v-list-item-content class="my-0 py-0">
            <v-list-item-title>
              <span>{{ device.device_id }}</span>
              <v-chip color="red" x-small class="float-right" v-if="device.state == 16">SOS</v-chip>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="ma-0 pa-0">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" small class="mr-1">
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item v-for="(action, i) in actions" :key="i" link>
                  <v-list-item-title @click="sendCommand(device.device_id, action.key)">{{ action.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-alert dense v-model="sosAlert" class="ma-2 alertBox" color="red darken-4" dark dismissible>{{ sosAlertMessage }}</v-alert>

      <v-text-field class="xs12 ma-2" hide-details :value="fcmToken" outlined></v-text-field>
      <v-btn @click="allowFCM"></v-btn>

      <GmapMap ref="gMapRef" :center="center" :zoom="15" map-type-id="terrain" style="width: 100%; height: 100%">
        <GmapMarker
          :key="index"
          v-for="(d, index) in devices"
          :position="{ lat: d.latitude, lng: d.longitude }"
          infoText="Hello"
          :clickable="true"
          :icon="require('@/assets/markers/red.svg')"
          :draggable="false"
          :label="{
            text: `ID: ${d.device_id}`,
            color: 'black',
            fontSize: '12px',
            className: 'markerLabel'
          }"
          @click="toggleInfoWindow(d, index)"
        />

        <gmapInfoWindow
          content="This is my info window content"
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        />
      </GmapMap>
    </v-main>

    <!-- DIALOGS -->
    <!-- SOS PHONE NUMBERS -->
    <v-dialog v-model="dialogSOSNumbers" width="500">
      <v-card>
        <v-card-title class="text-body blue darken-3 white--text" dense> Set SOS phone numbers </v-card-title>

        <v-card-text class="pa-5">
          <v-text-field v-model="sosNumber1" label="SOS number 1" placeholder="(e.g +4712345678)"></v-text-field>
          <v-text-field v-model="sosNumber2" label="SOS number 2" placeholder="(e.g +4712345678)"></v-text-field>
          <v-text-field v-model="sosNumber3" label="SOS number 3" placeholder="(e.g +4712345678)"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-3" text @click="saveSOSPhoneNumbers"> Save </v-btn>
          <v-btn color="red" text @click="closeSOSPhoneNumbers"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- UPLOAD INTERVAL -->
    <v-dialog v-model="dialogUploadInterval" width="500">
      <v-card>
        <v-card-title class="text-body blue darken-3 white--text" dense> Set upload interval </v-card-title>

        <v-card-text class="pa-5">
          <v-text-field v-model="uploadInterval" label="Interval (in seconds)"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-3" text @click="saveUploadInterval"> Save </v-btn>
          <v-btn color="red" text @click="closeUploadInterval"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TALKING CLOCK -->
    <v-dialog v-model="dialogTalkingClock" width="500">
      <v-card>
        <v-card-title class="text-body blue darken-3 white--text" dense> Talking clock switch </v-card-title>

        <v-card-text class="pa-5">
          <v-select v-model="talkingClock" :items="['On', 'Off']"></v-select>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-3" text @click="saveTalkingClock"> Save </v-btn>
          <v-btn color="red" text @click="closeTalkingClock"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Vue from "vue"
import GmapVue from "gmap-vue"
import { getGoogleMapsAPI } from "gmap-vue"
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyA0ADwYlgKJ_6lG-bWFW5bacHz72Nh0s4I",
    libraries: "places"
  },
  installComponents: true,
})

export default {
  data: () => ({
    devices: [],
    actions: [
      { key: "request-location", title: "Refresh location" },
      { key: "upload-interval", title: "Update upload interval" },
      { key: "set-sos-numbers", title: "Set SOS numbers" },
      { key: "talking-clock", title: "Talking clock" },
      { key: "ring-device", title: "Ring device" },
      { key: "restart", title: "Restart" },
      { key: "power-off", title: "Turn off" }
    ],
    fcmToken: null,
    center: {
      lat: 60.30102,
      lng: 11.16611
    },
    infoWinOpen: false,
    infoWindowPos: null,
    infoOptions: {
      content: "",
      //optional: offset infowindow so it visually sits nicely on top of our marker
      pixelOffset: {
        width: 0,
        height: -35
      }
    },

    sosAlert: false,
    sosAlertMessage: null,

    selectedDeviceId: null,

    dialogSOSNumbers: false,
    sosNumber1: null,
    sosNumber2: null,
    sosNumber3: null,

    dialogUploadInterval: false,
    uploadInterval: null,

    dialogTalkingClock: false,
    talkingClock: null,

    svgMarker: {
      path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      fillColor: "blue",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2
    }
  }),
  mounted() {
    this.locateMyLocation()
  },
  created() {
    
  },
  computed: {
    google: getGoogleMapsAPI
  },
  sockets: {
    connect() {
      this.fetchDevices()
    },
    update_devices(data) {
      console.log("** Updating devices...")
      this.devices = data
    },
    sos_triggered(data) {
      this.sosAlertMessage = `ALERT: SOS triggered on Device ID ${data.device_id}`
      this.sosAlert = true
      //this.centerDeviceLocation(data.device_id)
      console.log("** SOS triggered...")
    }
  },
  methods: {
    initFMC() {
      const firebaseConfig = {
        apiKey: 'AIzaSyAL3_HYvw4DKxMvAHAsCku7DyZM8BXeK8A',
        authDomain: 'sos-alert-localhost.firebaseapp.com',
        projectId: 'sos-alert-localhost',
        storageBucket: 'sos-alert-localhost.appspot.com',
        messagingSenderId: '822254825980',
        appId: '1:822254825980:web:bd9c1613e66f7445a44279',
        measurementId: 'G-YE2TSGPMNM'
      }
      initializeApp(firebaseConfig)
      const PUBLIC_VAPID_KEY = 'BGGv4yz0eooTySWzBkFfq1McnX_ltDs8e-I5V2cMFRH7HTI9UTYQ2gs_6rxrji0-zEnHVYBZoV7njOBPjAWzuNo'
      const messaging = getMessaging()
      onMessage(messaging, (payload) => {
        console.log('Message received. ', payload)
      })
      getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY }).then((currentToken) => {
        var date = new Date()
        date.setDate(date.getDate() + 4)
        if (currentToken) {
          this.fcmToken = currentToken
          console.log('Token is:', currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })
    },
    check () {
      if (!('serviceWorker' in navigator)) {
        throw new Error('No Service Worker support!')
      }
      if (!('PushManager' in window)) {
        throw new Error('No Push API Support!')
      }
    },
    async allowFCM() {
      let vm = this
      this.check()
      Notification.requestPermission().then(async function() {
        await vm.registerServiceWorker()
        vm.initFMC()
    });
      // if ('Notification' in window) {
      //   let permission = await Notification.requestPermission()
      //   if (permission === "granted") {
      //     this.initFMC()
      //   } else {
      //     alert("Notification permission denied")
      //   }
      // } else {
      //   console.log('Notification API not enabled')
      // }
    },
    async registerServiceWorker () {
      const swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      return swRegistration;
    },
    fetchDevices() {
      this.$socket.emit("get_devices")
    },
    locateMyLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude
        }
      })
    },
    centerDeviceLocation(device_id) {
      const device = this.devices.find((i) => i.device_id == device_id)

      if (device) {
        this.$refs.gMapRef.$mapPromise.then((map) => {
          map.setZoom(18)
          map.panTo({
            lat: device.latitude,
            lng: device.longitude
          })
        })
      }
    },
    toggleInfoWindow: function (device, idx) {
      this.infoWindowPos = { lat: device.latitude, lng: device.longitude }
      this.infoOptions.content = `
        <b>Device ID:</b> ${device.device_id}<br/>
        <b>Latitude:</b> ${device.latitude}<br/>
        <b>Longitude:</b> ${device.longitude}<br/>
        <b>Battery:</b> ${device.battery}<br/>
        <b>Accuracy:</b> ${device.accuracy}<br/>
        <b>Timestamp:</b> ${device.date} ${device.time} UTC
      `

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      }

      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
    sendCommand(device_id, action, value = null) {
      if (action == "set-sos-numbers") {
        this.openSOSPhoneNumbers(device_id)
        return
      }

      if (action == "upload-interval") {
        this.openUploadInterval(device_id)
        return
      }

      if (action == "talking-clock") {
        this.openTalkingClock(device_id)
        return
      }

      this.$socket.emit("device_command", { device_id: device_id, action: action, value: value !== null ? value : null })
    },
    saveSOSPhoneNumbers() {
      if (this.sosNumber1) {
        this.$socket.emit("device_command", { device_id: this.selectedDeviceId, action: "set-sos-number-1", value: this.sosNumber1 })
      }

      if (this.sosNumber2) {
        this.$socket.emit("device_command", { device_id: this.selectedDeviceId, action: "set-sos-number-2", value: this.sosNumber2 })
      }

      if (this.sosNumber3) {
        this.$socket.emit("device_command", { device_id: this.selectedDeviceId, action: "set-sos-number-3", value: this.sosNumber3 })
      }

      this.closeSOSPhoneNumbers()
    },
    openSOSPhoneNumbers(device_id) {
      this.selectedDeviceId = device_id
      this.dialogSOSNumbers = true
    },
    closeSOSPhoneNumbers() {
      this.sosNumber1 = null
      this.sosNumber2 = null
      this.sosNumber3 = null
      this.selectedDeviceId = null
      this.dialogSOSNumbers = false
    },

    saveUploadInterval() {
      if (this.uploadInterval) {
        this.$socket.emit("device_command", { device_id: this.selectedDeviceId, action: "upload-interval", value: this.uploadInterval })
      }
      this.closeUploadInterval()
    },
    openUploadInterval(device_id) {
      this.selectedDeviceId = device_id
      this.dialogUploadInterval = true
    },
    closeUploadInterval() {
      this.uploadInterval = null
      this.selectedDeviceId = null
      this.dialogUploadInterval = false
    },

    saveTalkingClock() {
      if (this.talkingClock) {
        this.talkingClock = this.talkingClock == "On" ? 1 : 0
        this.$socket.emit("device_command", { device_id: this.selectedDeviceId, action: "talking-clock", value: this.talkingClock })
      }
      this.closeTalkingClock()
    },
    openTalkingClock(device_id) {
      this.selectedDeviceId = device_id
      this.dialogTalkingClock = true
    },
    closeTalkingClock() {
      this.uploadInterval = null
      this.selectedDeviceId = null
      this.dialogTalkingClock = false
    }
  }
}
</script>

<style>
.alertBox {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  width: 99%;
}

.markerLabel {
  background: #fff;
  font-weight: bold;
  top: -38px;
  left: 0;
  position: relative;
  border: 1px solid #000;
  padding: 2px 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
</style>

<style scoped>
.v-list--dense .v-list-item {
  min-height: 23px !important;
}
</style>
